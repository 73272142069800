import { Flex, Box, Text } from '@chakra-ui/react'
import empty from './empty.png'


export default function Empty() {
    return (
        <Flex alignItems='center' justifyContent='center' width='100%' flexDirection='column' py='50px'>
            <img src={empty} style={{ width: '180px' }} />
            <Box textAlign='center'>
                <Text color='#4A4A4A' fontSize='20px' fontWeight='bold' mb='20px'>No Data</Text>
                <Text color='#999' fontSize='15px' mb='20px'>There are no Data assigned to you. Check back later.</Text>
            </Box>
        </Flex>
    )
}