import {
  Avatar,
  Button,
  Flex,
  Image,
  Link,
  Spacer,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import logoWhite from "assets/img/layout/logoWhite.png";
import { HSeparator } from "components/separator/Separator";
import React, { useEffect, useState } from "react";
import { info } from "views/auth/signIn/api";

export default function SidebarDocs() {
  const bgColor = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
  const borderColor = useColorModeValue("white", "navy.800");
  const [userInfo, setUserInfo] = useState({})
  useEffect(() => {
    info().then(res => {
      setUserInfo(res?.data || {})
    })
  }, [])
  return (
    <Flex
      justify='center'
      direction='column'
      align='center'
      bg={bgColor}
      borderRadius='30px'
      position='relative'>
      <Flex
        border='5px solid'
        borderColor={borderColor}
        bg='linear-gradient(135deg, #868CFF 0%, #4318FF 100%)'
        borderRadius='50%'
        w='94px'
        h='94px'
        align='center'
        justify='center'
        mx='auto'
        position='absolute'
        left='50%'
        top='-47px'
        transform='translate(-50%, 0%)'>
        <Avatar
          _hover={{ cursor: 'pointer' }}
          color="white"
          name={userInfo.avatar || userInfo.username}
          bg="transparent"
          size="lg"
          w="50px"
          h="50px"
        />
        {/* <Image src={logoWhite} w='40px' h='40px' /> */}
      </Flex>
      <Flex
        direction='column'
        mb='50px'
        align='center'
        justify='center'
        px='15px'
        pt='55px'>
        <Text
          fontSize={{ base: "lg", xl: "18px" }}
          color='white'
          fontWeight='bold'
          lineHeight='150%'
          textAlign='center'
          px='10px'
          mt="10px"
          mb='6px'>
          {userInfo.username}
        </Text>
        <Text
          fontSize='14px'
          color={"white"}
          fontWeight='500'
          px='10px'
          mb='6px'
          textAlign='center'>
          Balance: ${userInfo.money}
        </Text>
        <HSeparator my="15px"/>
        <Text
          fontSize='14px'
          color={"white"}
          fontWeight='500'
          px='10px'
          mb='6px'
          textAlign='center'
        >
         Recharge channels
        </Text>
        <Text
          fontSize='14px'
          color={"white"}
          fontWeight='500'
          px='10px'
          mb='6px'
          textAlign='center'
        >
          QQ：1918871571  
        </Text>
        <Text
          fontSize='14px'
          color={"white"}
          fontWeight='500'
          px='10px'
          mb='6px'
          textAlign='center'
        >
          Wechat：diaochamax
        </Text>
      </Flex>

    </Flex>
  );
}
