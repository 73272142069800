/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import CheckTable from "views/admin/default/components/CheckTable";
import TotalSpent from "views/admin/default/components/TotalSpent";
import { fetchStatistics, fetchStatisticsLine } from "./api";
import { fetchIpList } from "../ip/api";
import dayjs from "dayjs";
import { random } from "lodash";

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [statistics, setStatistics] = useState({})
  const [data, setData] = useState([])
  const [line, setLine] = useState({
    line: [],
    data: []
  })
  useEffect(()=>{
    fetchStatistics().then(res=>{
      setStatistics(res.data)
    })
    fetchIpList({page: 1}).then(res=>{
      console.log(res)
      setData(res.data.records)
    })
    fetchStatisticsLine().then(res=>{
      
      setLine({
        data: [...new Array(dayjs().daysInMonth())].map((item, index)=>{
          return res.data.find(d=>d.time == dayjs().startOf('month').add(index,'days').format('YYYY-MM-DD'))?.cost || 0
        }),
        line: [...new Array(dayjs().daysInMonth())].map((item, index)=>{
          return dayjs().startOf('month').add(index,'days').format('MM.DD')
        })
      })
    })
  }, [])
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
              }
            />
          }
          name='Today'
          value={'$' + (statistics.dayCost || 0) }
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='This Month'
          value={'$' + (statistics.monthCost || 0) }
        />
        <MiniStatistics name='Total Cost' value={'$' + statistics.totalCost || 0} />
        <MiniStatistics
          name='Your balance'
          value={'$' + (statistics.balance || 0) }
        />
        <MiniStatistics
          
          name='Number of detections'
          value={ statistics.totalCount || 0 }
        />
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name='Total Projects'
          value={ statistics.projectCount || 0 }
        />
      </SimpleGrid>

      <SimpleGrid  gap='20px' mb='20px'>
        <TotalSpent data={line.data} line={line.line}/>
      </SimpleGrid>
      <SimpleGrid  gap='20px' mb='20px'>
        <CheckTable  tableData={data} />
      </SimpleGrid>
    </Box>
  );
}
