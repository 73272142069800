import React from 'react';

import { Icon } from '@chakra-ui/react';
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdTableRows,
  MdLock,
} from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import Profile from 'views/admin/profile';
import Ip from 'views/admin/ip';
import Project from 'views/admin/project';

// Auth Imports
import SignInCentered from 'views/auth/signIn';

const routes = [
  {
    name: 'Dashboard',
    layout: '/dashborad',
    path: '/main',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: <MainDashboard />,
  },
  {
    name: 'Project',
    layout: '/dashborad',
    icon: <Icon as={MdTableRows} width="20px" height="20px" color="inherit" />,
    path: '/project',
    component: <Project />,
  },
  {
    name: 'Bill List',
    layout: '/dashborad',
    icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
    path: '/bill-list',
    component: <Ip />,
  },

  
  {
    name: 'Profile',
    layout: '/dashborad',
    hide: true,
    path: '/profile',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: <Profile />,
  },
  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: <SignInCentered />,
  }
];

export default routes;
