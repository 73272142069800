import axios from 'axios'
import { isEmpty } from 'lodash'


const request = axios.create({
  // baseURL: 'http://localhost:8081'
  baseURL: 'https://prod.researchdetect.com'
})

request.interceptors.request.use((options) => {
  options.headers = {
    authentication: localStorage.getItem('token'),
    tenant: localStorage.getItem('tenant'),
    ...options.headers,
  }
  return options
})

request.interceptors.response.use(res => {
  return res.data
}, (res) => {
  if ((res.response?.status == 401 || res.response?.status == 403) && localStorage.getItem('token')) {
    localStorage.clear()
    window.location.reload()
  }

})

export default request;