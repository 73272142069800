import request from 'utils/request'



export function fetchStatistics(params) {
  return request.get(`/user/statistics`, {params})
}




export function fetchStatisticsLine(params) {
  return request.get(`/user/statistics/line`, {params})
}





