import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import {} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState } from 'react';
// Chakra imports
import './global.css'
import Check from 'views/check/index.enc';
import CheckDev from 'views/checkTest/index.enc';
export default function Main() {
  // eslint-disable-next-line
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  return (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route
          path="dashborad/*"
          element={
            <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
          
        />
        <Route
          path="detection"
          element={
            <Check />
          }
          
        />
        <Route
          path="detection-dev"
          element={
            <CheckDev />
          }
          
        />
        <Route path="/" element={<Navigate to="/dashborad" replace />} />
      </Routes>
    </ChakraProvider>
  );
}
