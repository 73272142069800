/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

/* eslint-disable */

import {
  Box,
  Flex,
  Button,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useBoolean,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  Icon,
  useToast,
  Select,
  FormErrorMessage,
} from '@chakra-ui/react';
import Pagination from 'rc-pagination';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
// Custom components
import Card from 'components/card/Card';
import Menu from 'components/menu/MainMenu';
import { AndroidLogo, AppleLogo, WindowsLogo } from 'components/icons/Icons';
// Assets
import { PhoneIcon, AddIcon, EditIcon, DeleteIcon, ArrowLeftIcon, ArrowRightIcon, ChevronLeftIcon, ChevronRightIcon, CopyIcon } from '@chakra-ui/icons'
const columnHelper = createColumnHelper();
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from "react";
import { addProject, fetchProjectList, removeProject, resetProjectLink, updateProject } from './api';
import dayjs from 'dayjs';
import { HSeparator } from 'components/separator/Separator';
import Empty from 'components/empty';
import { Controller, Form, FormProvider, useForm } from 'react-hook-form';
import { FcRefresh } from 'react-icons/fc';
import { IoRefresh } from 'react-icons/io5';

export default function Settings() {
  // Chakra Color Mode
  const [sorting, setSorting] = React.useState([]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue('secondaryGray.500', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const columns = [
    columnHelper.accessor('projectName', {
      id: 'projectName',
      size: '400',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          NAME
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('type', {
      id: 'type',
      size: '400',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          type
        </Text>
      ),
      cell: (info) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="700">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor('createTime', {
      id: 'createTime',
      size: '400',
      header: () => (
        <Text
          justifyContent="space-between"
          align="center"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          DATE
        </Text>
      ),
      cell: (info) => (
        <Text color={textColor} fontSize="sm" fontWeight="700">
          {dayjs(info.getValue()).format('YYYY.MM.DD HH:mm')}
        </Text>
      ),
    }),
    columnHelper.accessor('actions', {
      id: 'actions',
      size: '100',
      header: () => (
        <Text
          justifyContent="space-between"
          align="right"
          fontSize={{ sm: '10px', lg: '12px' }}
          color="gray.400"
        >
          Actions
        </Text>
      ),
      cell: (info) => {
        const record = info.row.original
        return (
          <Flex align="right">
            <Button mr='10px' title="Copy Link" colorScheme='green' size='sm' onClick={() => {
              const textarea = document.createElement('textarea');
              textarea.value = window.location.href.split('?')[0].replace(window.location.pathname, '/detection?ident=' + record.ident)
              document.body.appendChild(textarea);
              textarea.select();
              document.execCommand('copy');
              document.body.removeChild(textarea);
            }}>
              <CopyIcon  />
            </Button>
            <Button mr='10px' title="Remove Project" colorScheme='red' size='sm' onClick={() => handleDelete(record)}>
              <DeleteIcon />
            </Button>
            <Button
              colorScheme='blue'
              size='sm'
              title="Edit Project"
               mr='10px'
              onClick={() => {
                method.reset({...record})
                openAction.on()
              }}
            >
              <EditIcon />
            </Button>

            <Button
              colorScheme='blue'
              size='sm'
              
              title="Reset Link"
              onClick={() => {
                handleResetLink(record)
              }}
            >
              <IoRefresh style={{color: '#fff'}} />
            </Button>
          </Flex>
        )
      },
    }),
  ];
  const [data, setData] = React.useState(() => []);
  const [page, setPage] = useState({ page: 1, pageSize: 15, total: 0 })
  const method = useForm({
    defaultValues: {
      projectName: '',
      type: 'lucid'
    },
    resolver: yupResolver(Yup.object().shape({
      projectName: Yup.string().required('Please input project name'),
      type: Yup.string().min(1, 'Please select project type').required('Please select project type')
    }))
  })
  const formData = method.watch()

  const [isOpen, openAction] = useBoolean(false)
  const [submitLoading, submitLoadingAction] = useBoolean(false)
  const toast = useToast()
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  const handleFetchProjectList = () => {
    fetchProjectList({
      ...page
    }).then(res => {
      console.log(res.data.records)
      setData(res.data.records || [])
      setPage({
        page: res.data.current,
        pageSize: res.data.size,
        total: res.data.total,
      })
    })
  }

  const handleSubmit = () => {
    debugger
    method.handleSubmit((e) => {
      console.log(e)
      submitLoadingAction.on()
      const fn = formData.id ? updateProject : addProject
      fn({
        ...formData
      }).then(res => {
        handleFetchProjectList()
        openAction.off()
      }).finally(() => {
        submitLoadingAction.off()
      })
    })()

  }


  const handleDelete = (record) => {

    removeProject(record).then(res => {
      handleFetchProjectList()
      toast({
        title: 'Success.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    })
  }


  const handleResetLink = (record) => {

    resetProjectLink(record).then(res => {
      handleFetchProjectList()
      toast({
        title: 'Success.',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
    })
  }

  const handlePaginationChange = (e) => {
    setPage({
      ...page,
      page: e
    })
    
  }


  useEffect(() => {
    handleFetchProjectList()
  }, [JSON.stringify(page)])

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Modal isOpen={isOpen} onClose={() => openAction.off()} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create or Update Project</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormProvider {...method}>
              <form onSubmit={(e)=>e.preventDefault()}>
                <Controller
                  name="projectName"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <FormControl mb='20px' isRequired isInvalid={error}>
                        <FormLabel >Project Name</FormLabel>
                        <Input value={field.value} onChange={field.onChange} />
                        <FormHelperText>please input your project name.</FormHelperText>
                        {error && <FormErrorMessage>{error?.message}.</FormErrorMessage>}
                      </FormControl>
                    )
                  }}
                />
                <Controller
                  name="type"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <FormControl >
                        <FormLabel>Project Type</FormLabel>
                        <Select value={field.value} onChange={field.onChange} width="200px" >
                          <option value='lucid'>Lucid</option>
                          <option value='cint'>Cint </option>
                          <option value='dynata'>Dynata</option>
                          <option value='inbrain'>Inbrain</option>
                          <option value='ps'>PS</option>
                          <option value='Sayso'>Sayso</option>
                          <option value='OpinionCapital'>OpinionCapital</option>
                          <option value='Toluna'>Toluna </option>
                          <option value='Elr'>Elr </option>
                          <option value='Edge'>Edge </option>
                          <option value='PA'>PA </option>
                          <option value='VQE'>VQE </option>
                          <option value='GMO'>GMO  </option>
                          <option value='surveysherpa'>surveysherpa  </option>
                          <option value='Market-Xcel'>Market-Xcel  </option>
                          <option value='B2B'>B2B  </option>
                          <option value='Channel'>Channel  </option>
                          <option value='Kouzi Cha'>Kouzi Cha  </option>
                          <option value='CB'>CB  </option>
                          <option value='GlobalSurvey'>GlobalSurvey  </option>
                        </Select>
                        {error && <FormErrorMessage>{error?.message}.</FormErrorMessage>}
                      </FormControl>
                    )
                  }}
                />
              </form>
            </FormProvider>


          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={() => openAction.off()}>
              Close
            </Button>
            <Button colorScheme='blue' onClick={handleSubmit} isLoading={submitLoading}>Submit</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Card
        flexDirection="column"
        w="100%"
        px="0px"
        overflowX={{ sm: 'scroll', lg: 'hidden' }}
      >
        <Flex px="25px" mb="8px" justifyContent="space-between" align="center">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            Project
          </Text>
          <Button
            variant='solid'
            colorScheme='blue'
            onClick={() => {
              method.reset({projectName: '', type: 'lucid', id: null})
              openAction.on()
            }}
          >
            Add Project
          </Button>
        </Flex>
        <Box>
          <Table variant="simple" color="gray.500" mb="0px" mt="12px" >
            <Thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    console.log(header)
                    return (
                      <Th
                        key={header.id}
                        colSpan={header.colSpan}
                        pe="10px"
                        borderColor={borderColor}
                        cursor="pointer"
                        onClick={header.column.getToggleSortingHandler()}
                        style={{width: header.getSize()}}
                      >
                        <Flex
                          justifyContent="space-between"
                          align="center"
                          fontSize={{ sm: '10px', lg: '12px' }}
                          color="gray.400"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                          {{
                            asc: '',
                            desc: '',
                          }[header.column.getIsSorted()] ?? null}
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            <Tbody>
              {table
                .getRowModel()
                .rows
                .map((row) => {
                  return (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Td
                            key={cell.id}
                            fontSize={{ sm: '14px' }}
                            borderColor="transparent"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext(),
                            )}
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}

            </Tbody>
          </Table>
          {!data.length && <Empty />}
          <HSeparator mb='20px' />
          <Box px='20px'>
            <Pagination
              className="pagination-data"
              showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total}`}
              onChange={handlePaginationChange}
              total={page.total}
              current={page.page}
              pageSize={page.pageSize}
              itemRender={(current, type, originalElement) => {
                if (type === 'prev') {
                  return <Button><ChevronLeftIcon fontSize={'18px'} /></Button>;
                }
                if (type === 'next') {
                  return <Button><ChevronRightIcon fontSize={'18px'} /></Button>;
                }
                return originalElement;
              }}
            />
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
