import request from 'utils/request'



export function fetchProjectList(params) {
  return request.get(`/user/project`, {params})
}




export function addProject(data) {
  return request.post(`/user/project`, data)
}



export function removeProject(data) {
  return request.delete(`/user/project/${data.id}`, data)
}


export function resetProjectLink(data) {
  return request.delete(`/user/project/refresh/${data.id}`, data)
}




export function updateProject(data) {
  return request.post(`/user/project/${data.id}`, data)
}


