import React from "react";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align='center' direction='column'>
      <Flex h='26px'  my='32px' mt={'12px'} fontSize={'23px'} userSelect={'none'}>
        <dd style={{fontWeight: 'bolder',color: '#333'}}>RESEARCH</dd> &nbsp;&nbsp; <span style={{color: '#333444',fontWeight: 'normal'}}>DETECT</span>
      </Flex>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
