import request from 'utils/request'



export function check(data) {
  return request.post(`/check`, data)
}




export function precheck(data) {
  return request.post(`/check/pre`, data)
}

