// Chakra Imports
import {
  Avatar,
  Button,
  Flex,
  Icon,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode,
  ModalCloseButton,
  useBoolean,
  ModalOverlay,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  ModalFooter,
  useToast,
} from '@chakra-ui/react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
// Custom Components
import { ItemContent } from 'components/menu/ItemContent';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// Assets
import navImage from 'assets/img/layout/Navbar.png';
import { MdNotificationsNone, MdInfoOutline } from 'react-icons/md';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';
import { FaEthereum } from 'react-icons/fa';
import routes from 'routes';
import { useNavigate } from 'react-router-dom';
import { info } from 'views/auth/signIn/api';
import { updatePassword } from 'api/user';
export default function HeaderLinks(props) {
  const { secondary } = props;
  const { colorMode, toggleColorMode } = useColorMode();
  // Chakra Color Mode
  const navbarIcon = useColorModeValue('gray.400', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const textColorBrand = useColorModeValue('brand.700', 'brand.400');
  const ethColor = useColorModeValue('gray.700', 'white');
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
  const ethBox = useColorModeValue('white', 'navy.800');
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
  );
  const navigator = useNavigate()
  const borderButton = useColorModeValue('secondaryGray.500', 'whiteAlpha.200');
  const [userInfo, setUserInfo] = useState({})
  const [isOpen, openAction] = useBoolean(false)
  const [submitLoading, submitLoadingAction] = useBoolean(false)
  const method = useForm()
  const toast = useToast()
  useEffect(()=>{
    info().then(res=>{
      console.log(res)
      setUserInfo(res?.data || {})
    })
  }, [])
  const handleSubmit = ()=>{
    method.handleSubmit((e)=>{
      updatePassword(e).then(()=>{
        toast({
          title: 'Success',
          duration: 2000,
          onCloseComplete: ()=>{
            // localStorage.clear()
            // window.location.reload()
          }
        })
        openAction.off()
      })
    })()
  }
  return (
    <Flex
      w={{ sm: '100%', md: 'auto' }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
      p="10px"
      pl='10px'
      borderRadius="30px"
      boxShadow={shadow}
    >
      {/* <SearchBar
        mb={() => {
          if (secondary) {
            return { base: '10px', md: 'unset' };
          }
          return 'unset';
        }}
        me="10px"
        borderRadius="30px"
      /> */}
      <Flex
        bg={ethBg}
        display={secondary ? 'flex' : 'none'}
        borderRadius="30px"
        ms="auto"
        p="6px"
        align="center"
        me="6px"
      >
        <Flex
          align="center"
          justify="center"
          bg={ethBox}
          h="29px"
          w="29px"
          borderRadius="30px"
          me="7px"
        >
          <Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
        </Flex>
        <Text
          w="max-content"
          color={ethColor}
          fontSize="sm"
          fontWeight="700"
          me="6px"
        >
          1,924
          <Text as="span" display={{ base: 'none', md: 'unset' }}>
            {' '}
            ETH
          </Text>
        </Text>
      </Flex>
      <SidebarResponsive routes={routes} />
      
      <Button
        variant="no-hover"
        bg="transparent"
        p="0px"
        minW="unset"
        mx='15px'
        minH="unset"
        h="18px"
        w="max-content"
        onClick={toggleColorMode}
      >
        <Icon
          me="10px"
          h="18px"
          w="18px"
          color={navbarIcon}
          as={colorMode === 'light' ? IoMdMoon : IoMdSunny}
        />
      </Button>
      <Menu>
        <MenuButton p="0px">
          <Avatar
            _hover={{ cursor: 'pointer' }}
            color="white"
            name={userInfo.avatar || userInfo.username}
            bg="#11047A"
            size="sm"
            w="40px"
            h="40px"
          />
        </MenuButton>
        <MenuList
          boxShadow={shadow}
          p="0px"
          mt="10px"
          borderRadius="20px"
          bg={menuBg}
          border="none"
         
        >
          <Flex w="100%" mb="0px">
            <Text
              ps="20px"
              pt="16px"
              pb="10px"
              w="100%"
              borderBottom="1px solid"
              borderColor={borderColor}
              fontSize="sm"
              fontWeight="700"
              color={textColor}
            >
              👋&nbsp; Hey, {userInfo.username}
            </Text>
          </Flex>
          <Flex flexDirection="column" p="10px">
            {/* <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              borderRadius="8px"
              px="14px"
              onClick={(e)=>{
                
                navigator("/dashborad/profile")
              }}
            >
              <Text fontSize="sm">Profile Settings</Text>
            </MenuItem> */}
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              
              borderRadius="8px"
              px="14px"
              onClick={(e)=>{
                openAction.on()
              }}
            >
              <Text fontSize="sm">Change Password</Text>
            </MenuItem>
            <MenuItem
              _hover={{ bg: 'none' }}
              _focus={{ bg: 'none' }}
              color="red.400"
              borderRadius="8px"
              px="14px"
              onClick={(e)=>{
                localStorage.clear()
                navigator("/auth/sign-in", {replace: true})
              }}
            >
              <Text fontSize="sm">Log out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>
      <Modal isOpen={isOpen} onClose={() => openAction.off()} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Change Password</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormProvider {...method}>
              <form onSubmit={(e)=>e.preventDefault()}>
                <Controller
                  name="password"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <FormControl mb='20px' isRequired isInvalid={error}>
                        <FormLabel >Password</FormLabel>
                        <Input value={field.value} onChange={field.onChange} />
                        <FormHelperText>please input your password.</FormHelperText>
                        {error && <FormErrorMessage>{error?.message}.</FormErrorMessage>}
                      </FormControl>
                    )
                  }}
                />
                
              </form>
            </FormProvider>


          </ModalBody>

          <ModalFooter>
            <Button mr={3} onClick={() => openAction.off()}>
              Close
            </Button>
            <Button colorScheme='blue' onClick={handleSubmit} isLoading={submitLoading}>Submit</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
